import { CATEGORY_DB_REF, NONPROFIT_DB_REF, PROGRAM_DB_REF } from '../../../global/firebase';
import { FIREBASE_DB_PATH, NONPROFIT_LOGO_BUCKET, NONPROFIT_COVER_BUCKET } from '../../../global/environment';

import { StringUtils } from '../../../utils';

class NonprofitProvider {
  constructor(firebaseDatabase, firebaseStorage) {
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = FIREBASE_DB_PATH;
  }

  getList = () => this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}`);

  getDetail = nid => this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}`).once('value');

  getPrograms = nid => this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}`);

  getCategory = () => this.db.ref(`${this.dbPath}/${CATEGORY_DB_REF}`);

  getTypeByCategory = cid => this.db.ref(`${this.dbPath}/${CATEGORY_DB_REF}/${cid}/types`);

  getCategoryOptions = () =>
    new Promise(resolve => {
      this.db
        .ref(`${this.dbPath}/${CATEGORY_DB_REF}`)
        .once('value')
        .then(snapshot => {
          const categories = [];
          snapshot.forEach(childSnapshot => {
            const types = [];
            Object.keys(childSnapshot.val().types).forEach(key => {
              types.push({
                id: childSnapshot.val().types[key].id,
                name: childSnapshot.val().types[key].name,
              });
            });

            categories.push({ id: childSnapshot.val().id, name: childSnapshot.val().name, types });
          });

          resolve({ categories });
        });
    });

  doUpdate = async (nid, data) => {
    const { logo, prevLogo, cover, prevCover } = data;
    let logoUrl = prevLogo;
    let coverUrl = prevCover;

    const updates = {};
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/name`] = data.name;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/address`] = data.street;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/city`] = data.city;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/state`] = data.countryState;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/zipcode`] = data.zipCode;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/federal`] = data.ein;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/category`] = data.category;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/type`] = data.type;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/website`] = data.website;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/contact/email`] = data.email;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/contact/phone`] = data.phone;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/description`] = data.description;

    if (logo) {
      const logoPath = `${NONPROFIT_LOGO_BUCKET}/${nid}.jpg`;
      const logoSnap = await this.storage.child(logoPath).put(logo, { contentType: logo.type });
      logoUrl = await logoSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/img/logo`] = logoUrl;
    }

    if (cover) {
      const coverPath = `${NONPROFIT_COVER_BUCKET}/${nid}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      coverUrl = await coverSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/img/cover`] = coverUrl;
    }

    await this.db.ref().update(updates);

    return { ...data, slug: StringUtils.slugify(data.name), img: { logo: logoUrl, cover: coverUrl } };
  };

  doUpdateSubscription = data => {
    const { nid, ...restData } = data;

    const updates = {};
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/campaignLimit`] = restData.campaignLimit;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/metricLimit`] = restData.metricLimit;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/outcomeLimit`] = restData.outcomeLimit;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/outputLimit`] = restData.outputLimit;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/programLimit`] = restData.programLimit;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/programBudgeting`] = restData.programBudgeting;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/goalSetting`] = restData.goalSetting;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/grantManagement`] = restData.grantManagement;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/scenarioAnalysis`] = restData.scenarioAnalysis;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/campaignManagement`] = restData.campaignManagement;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/crmManagement`] = restData.crmManagement;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/annualReporting`] = restData.annualReporting;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/impactAnalysis`] = restData.impactAnalysis;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/subscribe/financialAnalysis`] = restData.financialAnalysis;

    return this.db.ref().update(updates);
  };

  doRemove = async (nid, count) => {
    const nonprofit = await this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}`).once('value');
    if (nonprofit.val()) {
      await this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}`).remove();
      await this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}`).remove();

      try {
        const coverPath = `${NONPROFIT_COVER_BUCKET}/${nid}.jpg`;
        await this.storage.child(coverPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      try {
        const logoPath = `${NONPROFIT_LOGO_BUCKET}/${nid}.jpg`;
        await this.storage.child(logoPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };
}

export default NonprofitProvider;
