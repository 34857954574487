import qs from 'qs';

import { PROFILE_DB_REF } from '../../global/firebase';
import { MSG_PROFILE_NOT_FOUND } from '../../global/messages';
import { LOCAL_STORAGE_PREFIX, FIREBASE_DB_PATH, FIREBASE_API_URL, AVATAR_BUCKET } from '../../global/environment';

import { HttpUtils } from '../../utils';

class UserProvider {
  constructor(firebaseAuth, firebaseDatabase, firebaseStorage) {
    this.auth = firebaseAuth;
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = FIREBASE_DB_PATH;
  }

  getProfile = uid => this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}`).once('value');

  getAdminList = () =>
    this.db
      .ref(`${this.dbPath}/${PROFILE_DB_REF}`)
      .orderByChild('type')
      .equalTo('admin');

  getAuthUserProfile = authUser =>
    new Promise(resolve => {
      this.auth.currentUser.getIdToken(true).then(idToken => {
        this.getProfile(authUser.uid).then(snapshot => {
          if (snapshot.val()) {
            const dbProfile = {
              avatar: snapshot.val().avatar ? snapshot.val().avatar : null,
              firstName: snapshot.val().firstName,
              lastName: snapshot.val().lastName,
              location: snapshot.val().location ? snapshot.val().location : '',
              jobTitle: snapshot.val().jobTitle ? snapshot.val().jobTitle : '',
              emailVerified: snapshot.val().emailVerified ? snapshot.val().emailVerified : false,
              uid: snapshot.val().uid,
              token: snapshot.val().token ? snapshot.val().token : null,
              social: snapshot.val().social ? snapshot.val().social : false,
              type: snapshot.val().type ? snapshot.val().type : '',
            };

            // merge auth and db user
            const authUserProfile = {
              uid: authUser.uid,
              displayName: authUser.displayName,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              photo: authUser.photoURL ? authUser.photoURL : null,
              idToken,
              ...dbProfile,
            };

            resolve(authUserProfile);
          }
        });
      });
    });

  doVerifyAuthUser = authUser =>
    new Promise((resolve, reject) => {
      this.getProfile(authUser.uid).then(snapshot => {
        if (!snapshot.val()) {
          return reject(new Error(MSG_PROFILE_NOT_FOUND));
        }

        const dbProfile = {
          email: authUser.email,
          firstName: snapshot.val().firstName,
          emailVerified: snapshot.val().emailVerified ? snapshot.val().emailVerified : false,
          uid: snapshot.val().uid,
          token: snapshot.val().token ? snapshot.val().token : null,
          social: snapshot.val().social ? snapshot.val().social : false,
          type: snapshot.val().type ? snapshot.val().type : '',
        };

        resolve(dbProfile);
      });
    });

  doSignOut = () => {
    localStorage.removeItem(LOCAL_STORAGE_PREFIX);
    this.auth.signOut();
  };

  doRegisterToken = uid =>
    new Promise(resolve => {
      const token = Math.random()
        .toString(36)
        .slice(-8);
      this.db
        .ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}/token`)
        .set(token)
        .then(() => {
          resolve({ status: 'registered', token });
        });
    });

  doSendVerify = (profile, id = 1) =>
    new Promise((resolve, reject) => {
      const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
      const mode = this.dbPath === 'dev' ? 'DEV' : 'PROD';
      const VERIFY_URL = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/sendVerify`;
      const formData = {
        id,
        mode,
        data: JSON.stringify(profile),
        token: '8pS3d6GxECa9hNQd',
      };

      HttpUtils.postRequest(VERIFY_URL, qs.stringify(formData), axiosConfig)
        .then(() => {
          resolve({ status: 'send' });
        })
        .catch(err => {
          reject(err.message);
        });
    });

  doUpdateUserProfile = async data => {
    const { uid, firstName, lastName, location, jobTitle, avatar, prevAvatar } = data;
    let avatarUrl = prevAvatar;

    if (avatar) {
      const avatarPath = `${AVATAR_BUCKET}/${uid}.jpg`;
      const avatarSnap = await this.storage.child(avatarPath).put(avatar, { contentType: avatar.type });
      avatarUrl = await avatarSnap.ref.getDownloadURL();
    }

    const idToken = await this.auth.currentUser.getIdToken(true);
    const axiosConfig = {
      headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
    };
    const POST_API_URL = `${FIREBASE_API_URL}/v1/admin/${uid}`;

    const payload = {
      firstName,
      lastName,
      jobTitle,
      avatar: avatarUrl,
    };

    await HttpUtils.putRequest(POST_API_URL, payload, axiosConfig);

    return { uid, firstName, lastName, location, jobTitle, avatar: avatarUrl };
  };

  doRemoveAdmin = async (uid, count) => {
    const userAdmin = await this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}`).once('value');
    if (userAdmin.val()) {
      try {
        const idToken = await this.auth.currentUser.getIdToken(true);
        const axiosConfig = {
          headers: { Authorization: `Bearer ${idToken}` },
        };
        const DELETE_API_URL = `${FIREBASE_API_URL}/v1/admin/${uid}`;

        await HttpUtils.deleteRequest(DELETE_API_URL, axiosConfig);
      } catch (error) {
        console.log(error);
      }

      try {
        const avatarPath = `${AVATAR_BUCKET}/${uid}.jpg`;
        await this.storage.child(avatarPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };
}

export default UserProvider;
