export const MSG_PROFILE_NOT_FOUND =
  'There is no user record corresponding to this identifier. The user may have been deleted or in dev mode.';
export const MSG_RESET_PWD_SUCCESS = 'Your reset password link was successful send! Please check your email';
export const MSG_REGISTER_SUCCESS = 'Email sent successfully! Please verify your email';
export const MSG_PROFILE_UPDATED = 'Your account information successfully updated.';
export const MSG_PASSWORD_UPDATED = 'Your password successfully updated.';
export const MSG_ACCESS_DENIED = 'Your email address is not registered with a Fundra account.';
export const MSG_NONPROFIT_UPDATED = 'Nonprofit information successfully updated.';
export const MSG_NONPROFIT_SUBSCRIPTION_UPDATED = 'Nonprofit subscription successfully updated.';
export const MSG_NONPROFIT_APPROVED = 'Requested nonprofit successfully approved.';
export const MSG_NONPROFIT_DELETED = 'Nonprofit successfully deleted.';
export const MSG_USER_CREATED = 'User successfully created as admin.';
export const MSG_USER_DELETED = 'User successfully removed as admin.';
