import { CATEGORY_SET, CATEGORY_CLEAR } from './types';

const INITIAL_STATE = {
  categories: null,
};

const applySetCategories = (state, action) => ({
  ...state,
  categories: action.categories,
});

const applyClearCategories = state => ({
  ...state,
  categories: null,
});

function nonprofitReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CATEGORY_SET:
      return applySetCategories(state, action);
    case CATEGORY_CLEAR:
      return applyClearCategories(state);
    default:
      return state;
  }
}

export default nonprofitReducer;
