import { NONPROFIT_DB_REF, NONPROFIT_REQUESTED_DB_REF, PROFILE_DB_REF, PROGRAM_DB_REF } from '../../../global/firebase';
import { FIREBASE_DB_PATH, NONPROFIT_COVER_BUCKET, NONPROFIT_LOGO_BUCKET } from '../../../global/environment';

class NonprofitRequestedProvider {
  constructor(firebaseDatabase, firebaseStorage) {
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = FIREBASE_DB_PATH;
  }

  getDetail = nid => this.db.ref(`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}/${nid}`).once('value');

  getList = () => this.db.ref(`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}`);

  doApprove = async (nid, count) => {
    const nonprofit = await this.db.ref(`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}/${nid}`).once('value');
    if (nonprofit.val()) {
      await this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}`).set({ ...nonprofit.val() });
      await this.db.ref(`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}/${nid}`).remove();
      await this.db
        .ref(`${this.dbPath}/${PROFILE_DB_REF}/${nonprofit.val().agent}/nonprofit/requested/${nid}`)
        .remove();

      return { status: 'approved', count };
    }

    return { status: 'not found' };
  };

  doRemove = async (nid, count) => {
    const nonprofit = await this.db.ref(`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}/${nid}`).once('value');
    if (nonprofit.val()) {
      await this.db.ref(`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}/${nid}`).remove();
      await this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}`).remove();
      await this.db
        .ref(`${this.dbPath}/${PROFILE_DB_REF}/${nonprofit.val().agent}/nonprofit/requested/${nid}`)
        .remove();

      try {
        const coverPath = `${NONPROFIT_COVER_BUCKET}/${nid}.jpg`;
        await this.storage.child(coverPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      try {
        const logoPath = `${NONPROFIT_LOGO_BUCKET}/${nid}.jpg`;
        await this.storage.child(logoPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };
}

export default NonprofitRequestedProvider;
